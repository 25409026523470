import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/rready/1.png"
import img2 from "../../images/rready/2.png"
import img3 from "../../images/rready/3.png"
import img4 from "../../images/rready/4.png"

import img0 from "../../images/rready/0.png"

// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    rready is a Zurich-based startup that offers innovation and cultural transformation as a service.
                    <p/>
                    <a href="http://www.distylerie.com/" target="_blank">distylerie</a> created the branding, I wrote the texts for their website, in English and German. This included longpaper expert texts on innovation.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    rready ist ein Startup aus Zürich, welches Innovation und kulturelle Transformation als Service anbietet.
                    <p/>
                    <a href="http://www.distylerie.com/" target="_blank">distylerie</a> machte das Branding, ich schrieb die Texte für ihre Webseite, auf Deutsch und Englisch. Dabei waren auch längere Expertenartikel zu Innovation.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>





    <img src={img0}/>
    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>





      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
